<template lang="pug">
	article#contato
		Formulario
		FAQ
</template>

<script>
import Formulario from '@sections/contato/formulario/Formulario.vue'
import FAQ from '@sections/contato/faq/Faq.vue'

export default {
	components: {
		Formulario, FAQ
	}
}
</script>

<style lang="stylus" scoped src="./Contato.styl"></style>