<template lang="pug">
	section#formulario
		.wrapper
			div.contato
				h2 Contato
				p {{conteudo.conteudo}}
				div
					span Endereço
					strong {{conteudo.endereco}}
				div
					span Telefone
					a(:href="conteudo.telefone") {{conteudo.telefone}}
				div
					span Horário de Funcionamento
					strong {{conteudo.hora}}
			div.form
				form(v-on:submit.prevent="sendContato")
					div.select._100(:class="{'erro': errors.assunto_id}")
						select._100(v-model="contato.assunto_id")
							option(disabled, :value="null") Selecione um assunto
							option(v-for='assunto,index in assuntos', :value='assunto.id') {{assunto.assunto}}
						span(v-if="errors.assunto_id") {{errors.assunto_id[0]}}
					div.input._100(:class="{'erro': errors.nome}")
						label
							img(:src="require('@images/icones/icone-nome.png')")
						input(placeholder="Nome", name="nome" v-model="contato.nome")
						span(v-if="errors.nome") {{errors.nome[0]}}

					div.input._50(:class="{'erro': errors.email}")
						label
							img(:src="require('@images/icones/icone-email.png')")
						input(placeholder="E-mail", name="email" v-model="contato.email")
						span(v-if="errors.email") {{errors.email[0]}}

					div.input._50(:class="{'erro': errors.telefone}")
						label
							img(:src="require('@images/icones/icone-telefone.png')")
						input(placeholder="Telefone", name="telefone" v-model="contato.telefone" v-mask="['(##) #####-####','(##) ####-####']")
						span(v-if="errors.telefone") {{errors.telefone[0]}}

					div.input._100(:class="{'erro': errors.mensagem}")
						textarea(placeholder="Mensagem" v-model="contato.mensagem")
						span(v-if="errors.mensagem") {{errors.mensagem[0]}}
					button(:disabled="disabled") Enviar
					span(v-if="disabled && textButton == 'Enviado'") {{textButton}}


</template>

<script>
import { mask } from "vue-the-mask";

export default {
	directives: { mask },
	data() {
		return {
			assuntos: {},
			conteudo: {},
			contato: {
				assunto_id: null
			},
			errors: {},
			disabled: false,
			textButton: "Enviar",
		};
	},
	created() {
		this.loadAssuntos();
		this.loadConteudo();
	},
	methods: {
		loadAssuntos() {
			this.$axios.get(`api/contato/assuntos`)
				.then(response => {
					this.assuntos = response.data;
				});
		},
		loadConteudo() {
			this.$axios.get(`api/contato/conteudo`).then(response => {
				this.conteudo = response.data;
			});
		},
		sendContato() {
			this.errors = {}
			this.disabled = true
			this.textButton = "Enviando"
			this.$axios
				.post(`api/contato`, this.contato)
				// eslint-disable-next-line
				.then(response => {
					this.contato = {},
					this.contato.assunto_id = null
					this.textButton = "Enviado"
				})
				.catch(error => {
					if (error.response.status == 422)
						this.errors = error.response.data.errors;
				})
				.finally(() => {
					setTimeout(() => {
						this.disabled = false
						this.textButton = "Enviar"
					}, 5000)
				});
		}
	}
};
</script>

<style lang="stylus" scoped src="./Formulario.styl"></style>
