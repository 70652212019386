<template lang="pug">
	section#faq
		.wrapper
			h2 Perguntas Frequentes
			ul
				li(v-for="question in questions")
					h3 {{ question.pergunta }}
					p(v-html="question.resposta")
			button(v-if="paginacao.current_page < paginacao.last_page", @click="loadMore") Mais perguntas
</template>

<script>
import qs from 'qs';

export default {
	data() {
		return {
			paginacao: {},
			questions: [],
			params: {
				page: null,
				limit: 9,
				sort: "-published_at"
			}
		};
	},
	created() {
		this.loadFaqs();
	},
	methods: {	
		loadFaqs(params = null) {
			if (params)
				this.params = Object.assign({}, this.params, params)
			this.$axios
				.get(`api/faqs?${qs.stringify(this.params, { skipNulls: true })}`)
				.then(response => {
					let data = response.data.registros.data;
					this.paginacao = response.data.registros;
					if (this.params.page)
						data.forEach(question => this.questions.push(question));
					else
						this.questions = data;
				});
		},
		loadMore() {
			this.loadFaqs({
				page: this.paginacao.current_page + 1
			})
		}
	}
};
</script>

<style lang="stylus" scoped src="./Faq.styl"></style>
